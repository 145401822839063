import React, { useLayoutEffect } from 'react'
import queryString from 'query-string'
import Cookies from 'js-cookie'
import Header from '../components/header'
import IntroAlt from '../components/sections/intro/alt'
import Layout from '../components/layout'
import HomeContent from '../components/sections/home-content'
import Partners from '../components/sections/partners'
import SEO from '../components/seo'
// import Zip from "../components/apply/zip"
import Existing from '../components/apply/existing'
import auto from '../images/auto-man.jpg'

const checklist = [
  'Finding a quote with Auto Insurance Companion is completely free.',
  'We’ve helped thousands find affordable auto insurance.',
  'Getting a quote takes less than 5 minutes of your time.',
]

const FullAltPage = ({ location }) => {
  useLayoutEffect(() => {
    const parsed = queryString.parse(location.search)
    const nf_ab = parsed.nf_ab
    if (nf_ab === '0') {
      Cookies.set('nf_ab', '0')
    } else {
      Cookies.set('nf_ab', '1')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Layout phone="888-996-1365" location={location}>
      <SEO
        title="Auto Insurance Offers"
        keywords={[`auto`, `insurance`, `plans`, `affordable`]}
      />
      <Header phone="888-996-1365" />
      <IntroAlt
        title="Save up to 52% on your car insurance"
        subTitle="First, just answer a few questions to get your <span><strong>free</strong></span> car insurance quote!"
        colType="right-col"
        bgImage={auto}
        checklist={checklist}
      >
        <div className="form-box">
          <div className="contacts-form alt text-center">
            <Existing shouldNavigate={true} />
          </div>
        </div>
      </IntroAlt>
      <main id="main">
        <Partners />
        <HomeContent />
      </main>
    </Layout>
  )
}

export default FullAltPage
