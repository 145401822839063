import React from 'react'
import queryString from 'query-string'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'

class Existing extends React.Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const parsed = queryString.parse(window.location.search)
    if (parsed.existing) {
      this.props.submitData({ existing: 'Yes' })
      this.props.nextStep('existing')
    }
  }
  handleSubmit = (value) => {
    this.props.submitData({ existing: value })
    this.props.nextStep('existing')
    if (this.props.shouldNavigate) {
      navigate('/apply/vehicle')
    }
  }

  render = () => (
    <div>
      <strong className="h4">Are you currently insured?</strong>
      <div className="row">
        <div className="col full-width inline-buttons">
          <button
            className="btn"
            onClick={() => {
              this.handleSubmit('No')
            }}
          >
            No
          </button>
          <button
            className="btn"
            onClick={() => {
              this.handleSubmit('Yes')
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}

Existing.propTypes = {
  shouldNavigate: PropTypes.bool,
}

Existing.defaultProps = {
  shouldNavigate: false,
}

export default Existing
